// Projects.js
import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import { useSpring, animated } from 'react-spring';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Fetch projects from the server or import the local JSON file
    import('./projects.json').then((data) => setProjects(data.default));
  }, []);

  const animation = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <animated.section id="projects" style={animation}>
        <HelmetProvider>
      <h2 className='work-heading'>Projects</h2>
      <Helmet>
        <meta name='Projects' description='Please find below the projects I have worked on recently'/>
      </Helmet>
      <div className="projects-list">
        {projects.map((project, index) => (
          <div className="project" key={index}>
            <h3 className="work-title">{project.title}</h3>
            <p>{project.description}</p>
            <a href={project.link} target="_blank" rel="noopener noreferrer">
              View on GitHub
              <FontAwesomeIcon icon={faGithub} style={{ marginLeft: '5px' }} />
            </a>
          </div>
        ))}
      </div>
      </HelmetProvider>
    </animated.section>
  );
}

export default Projects;
