// Home.js
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSpring, animated } from 'react-spring';

const Home = () => {
    const animation = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <animated.section id="home" style={animation}>
    <HelmetProvider>
    <h2 className='work-heading'>Akash Balani - Curious Website</h2>
      <Helmet>
        <meta name="description" content="Welcome to the portfolio of Akash Balani, a passionate Cloud Engineer." />
      </Helmet>
      <p>
        Explore my projects, learn more about my skills and experience, and feel free to
        get in touch if you have any questions or if you're interested in collaborating.
      </p>
      <div className="cta">
        <p>Ready to start a project together?</p>
        <p><Link to="/contact">Contact Me</Link></p>
        
      </div>
      </HelmetProvider>
    </animated.section>
  );
}

export default Home;


