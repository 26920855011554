// Contact.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useSpring, animated } from 'react-spring';

const Contact = () => {
  const animation = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <animated.section id="contact" style={animation}>
      <h2 className='work-heading'>Contact Me</h2>
      <HelmetProvider>
      <Helmet>
        <meta name="description" content="Contact me using any of these ways" />
      </Helmet>
      <p>
        Feel free to reach out if you have any questions, collaboration requests,
        or just want to say hello!
      </p>
      <ul>
        <li>
          <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '8px' }} />
          <a href="mailto:balani.aakash@gmail.com">balani.aakash@gmail.com</a>
        </li>
        <li>
          <FontAwesomeIcon icon={faLinkedin} style={{ marginRight: '8px' }} />
          <a href="https://www.linkedin.com/in/balaniakash" target="_blank" rel="noopener noreferrer">
            linkedin.com/in/balaniakash
          </a>
        </li>
      </ul>
      </HelmetProvider>
    </animated.section>
  );
}

export default Contact;
