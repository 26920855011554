import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import workData from "./Work.json"; // Import the work experience JSON
import "./Work.css"; // Import CSS for styling

const Work = () => {
  const [selectedWork, setSelectedWork] = useState(null); // State for the selected work experience

  // Function to handle clicking on a card
  const handleCardClick = (work) => {
    setSelectedWork(work);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedWork(null);
  };

  return (
    <HelmetProvider>
      <section className="work-section">
        <Helmet>
          <meta name="description" content="Work Experience Page" />
        </Helmet>

        <h2 className="work-heading">Work Experience</h2>
        <div className="work-container">
          {workData.map((work, index) => (
            <div
              key={index}
              className="work-card"
              onClick={() => handleCardClick(work)}
            >
              <div className="work-header">
                <h3 className="work-title">{work.title}</h3>
                <h4 className="work-company">{work.company}</h4>
              </div>
              <div className="work-meta">
                {work.duration && <p className="work-duration">{work.duration}</p>}
                {work.location && <p className="work-location">{work.location}</p>}
              </div>
              <p className="work-description">
                {work.description.split("\n")[0]}...
              </p>
            </div>
          ))}
        </div>

        {/* Modal for detailed view */}
        {selectedWork && (
          <div className="modal-overlay" onClick={closeModal}>
            <div
              className="modal-content"
              onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking inside
            >
              <h3 className="modal-title">{selectedWork.title}</h3>
              <h4 className="modal-company">{selectedWork.company}</h4>
              <p className="modal-meta">
                {selectedWork.duration} | {selectedWork.location}
              </p>
              <p className="modal-description" style={{ whiteSpace: "pre-line" }}>
                {selectedWork.description}
              </p>
              {selectedWork.skills && (
                <p className="modal-skills">
                  <strong>Skills:</strong> {selectedWork.skills}
                </p>
              )}
              <button className="modal-close" onClick={closeModal}>
                Close
              </button>
            </div>
          </div>
        )}
      </section>
    </HelmetProvider>
  );
};

export default Work;
